var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8"},[_c('b-field',{attrs:{"label":"Concepto","label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":"Concepto","expanded":""},model:{value:(_vm.cashFlow.concept),callback:function ($$v) {_vm.$set(_vm.cashFlow, "concept", $$v)},expression:"cashFlow.concept"}},[_c('option',{attrs:{"value":"INGRESS"}},[_vm._v("Ingreso")]),_c('option',{attrs:{"value":"EGRESS"}},[_vm._v("Egreso")])])],1),(
          _vm.isAdmin &&
          (this.cashFlow.type === 'commercial.partner' ||
            this.cashFlow.type === 'commercial.provider' ||
            this.cashFlow.type === 'profit' ||
            this.cashFlow.type === 'driver')
        )?_c('b-field',{attrs:{"label":_vm.isBudget ? 'Presupuesto ' : 'Facturado'}},[_c('b-switch',{attrs:{"rounded":true,"outlined":true,"type":"is-success","left-label":true,"passive-type":"is-primary"},model:{value:(_vm.isBudget),callback:function ($$v) {_vm.isBudget=$$v},expression:"isBudget"}})],1):_vm._e(),_c('b-field',{attrs:{"label":"Método de pago","label-position":"inside"}},[_c('b-select',{attrs:{"placeholder":"Método de pago","expanded":""},model:{value:(_vm.cashFlow.paymentMethod),callback:function ($$v) {_vm.$set(_vm.cashFlow, "paymentMethod", $$v)},expression:"cashFlow.paymentMethod"}},_vm._l((_vm.payMethods.map(({ name }) => name)),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(option))+" ")])}),0)],1),_c('TypeInput',{attrs:{"type":_vm.cashFlow.type,"concept":_vm.cashFlow.concept},on:{"select":_vm.handleSelectType}}),(_vm.hasThird)?_c('ThirdPartyLookup',{attrs:{"thirds":_vm.getThirds,"third":_vm.selectedThird,"type":_vm.thirdType},on:{"select":_vm.handleSelectThird}}):_vm._e(),(_vm.isInternal)?_c('TagInput',{on:{"select":_vm.handleSelectTag}}):_vm._e()],1),_c('div',{staticClass:"column is-4"},[(
            _vm.cashFlow.concept === 'INGRESS' &&
            _vm.isCommercialPartner &&
            _vm.cashFlow.partner
          )?_c('CustomerLookup',{attrs:{"label":"Asociar cuenta cliente","partner":_vm.cashFlow.partner,"onlyWithPartner":true},on:{"select":_vm.handleSelectCustomer}}):_vm._e(),_c('b-field',{attrs:{"label":"Importe"}},[_c('b-input',{attrs:{"type":"number","placeholder":"0.00","step":"0.01","icon":"currency-usd","expanded":""},model:{value:(_vm.cashFlow.amount),callback:function ($$v) {_vm.$set(_vm.cashFlow, "amount", $$v)},expression:"cashFlow.amount"}})],1),_c('b-field',{attrs:{"label":"Descripción","label-position":"inside"}},[_c('b-input',{attrs:{"maxlength":"200\n          ","type":"textarea"},model:{value:(_vm.cashFlow.description),callback:function ($$v) {_vm.$set(_vm.cashFlow, "description", $$v)},expression:"cashFlow.description"}})],1),_c('Button',{attrs:{"data-cy":"create-cashflow","disabled":(_vm.hasThird && !_vm.selectedThird) ||
          _vm.cashFlow.amount === 0 ||
          !_vm.cashFlow.paymentMethod,"type":"is-success","label":"Confirmar","pulled":"right","onClickFn":_vm.handleCreateCashFlow}},[_vm._v("Confirmar")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }