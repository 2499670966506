<template>
  <b-field>
    <b-taginput
      v-model="tags"
      :data="filteredTags"
      autocomplete
      :allow-new="false"
      maxtags="1"
      :open-on-focus="openOnFocus"
      icon="tag"
      placeholder="Etiqueta"
      @typing="getFilteredTags"
      @input="handleInput"
    >
    </b-taginput>
  </b-field>
</template>

<script>
const customTags = [
  'ADMIN',
  'AJUSTE STOCK',
  'COMU',
  'IMPUESTOS',
  'SIST',
  'LOG',
  'LOCAL',
  'RETIROS',
  'PROD',
  'VARIOS',
  'VIANDAS',
  'CONT',
  'DEVOLUCIONES',
];

export default {
  name: 'TagInput',
  props: {
    concept: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      filteredTags: customTags,
      isSelectOnly: false,
      tags: [],
      allowNew: true,
      openOnFocus: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleInput(tag) {
      this.$emit('select', tag[0]);
    },
    getFilteredTags(text) {
      this.filteredTags = customTags.filter(
        (option) => option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0,
      );
    },
  },
  mounted() {},
};
</script>
