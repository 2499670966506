<template>
  <div class="mt-4">
    <div v-if="staleProviderAudit.count > 0" class="bg-red-400 flex items-center justify-between px-2">
      <span>Necesita actualizar auditoria proveedores</span>
      <Button :onClickFn="refreshAudit" label="Actualizar" type="is-danger">Auditar</Button>
    </div>

    <b-table :paginated="isPaginated" :perPage="perPage" :data="providerBalances" :striped="true" :narrowed="true"
      :show-detail-icon="false" ref="table" detailed>
      <b-table-column field="name" label="Proveedor" centered sortable v-slot="props">
        <span class="cursor-pointer" @click="handleShowAuditProviderModal(props.row)"> {{ props.row.name }}</span>
      </b-table-column>
      <b-table-column field="receipts" label="Facturas" centered sortable v-slot="props">
        ${{ props.row.receipts | rounded }}
      </b-table-column>
      <b-table-column field="cashflows" label="Pagos" centered sortable v-slot="props">
        ${{ props.row.cashflows | rounded }}
      </b-table-column>
      <b-table-column field="debtBalances" label="Saldo de deuda" centered sortable v-slot="props">
        ${{ props.row.debtBalance | rounded }}
      </b-table-column>

      <b-table-column field="inventory" label="Stock" centered sortable v-slot="props">
        ${{ props.row.inventory | rounded }}
      </b-table-column>
      <b-table-column field="finalBalance" label="Saldo Activate" centered sortable v-slot="props">
        ${{ props.row.finalBalance | rounded }}
      </b-table-column>
      <b-table-column field="pendingBuys" label="Compras Pendientes" centered sortable v-slot="props">
        ${{ props.row.pendingBuys | rounded }}
      </b-table-column>

      <b-table-column field="" label="Saldo final" centered sortable v-slot="props">
        ${{ (props.row.finalBalance + props.row.pendingBuys) | rounded }}
      </b-table-column>
      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalReceipts }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalCashFlows }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalDebtBalance }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalInventory }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalFinalBalance }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalPendingBuys }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalBalanceFinal }}</div>
        </th>

        <th class="is-hidden-mobile"></th>
      </template>
    </b-table>

    <GenericModal :active="isAuditProviderModalActive" @dismiss="isAuditProviderModalActive = false">
      <div v-if="isLoading.providerAudit">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 animate-spin" fill="none" viewBox="0 0 24 24"
          stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <List v-if="!isLoading.providerAudit && providerAudit" :data="providerAudit.transactions"
        :operationPermission="false">
      </List>
    </GenericModal>

  </div>
</template>

<script>
import { mapState } from 'pinia';
import ApiService from '../../services/ApiService';
import { round, sum } from '../../util/helpers';
import GenericModal from '../GenericModal.vue';
import useAuditStore from '../../store/audit';
import List from '../../views/provider/account/List.vue';

const { StatisticsService } = ApiService;

export default {
  name: 'ProviderBalances',
  props: {
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },

    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GenericModal,
    List,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      providerBalances: [],
      isLoading: false,
      isAuditProviderModalActive: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(useAuditStore, ['providerAudit']),
    ...mapState(useAuditStore, ['staleProviderAudit']),
    totalReceipts() {
      return round(sum(this.providerBalances, 'receipts'));
    },
    totalCashFlows() {
      return round(sum(this.providerBalances, 'cashflows'));
    },
    totalDebtBalance() {
      return round(sum(this.providerBalances, 'debtBalance'));
    },
    totalInventory() {
      return round(sum(this.providerBalances, 'inventory'));
    },
    totalFinalBalance() {
      return round(sum(this.providerBalances, 'finalBalance'));
    },
    totalPendingBuys() {
      return round(sum(this.providerBalances, 'pendingBuys'));
    },
    totalBalanceFinal() {
      return round(
        sum(this.providerBalances, 'pendingBuys') + sum(this.providerBalances, 'finalBalance'),
      );
    },
  },
  methods: {
    async refreshAudit() {
      await this.auditStore.performProviderAudit();
      await this.fetchProviderBalances();
      await this.auditStore.getStaleProviderAudit();
    },
    toPay(partner) {
      return round(partner.toPayBalance);
    },
    async handleShowAuditProviderModal(provider) {
      this.isAuditProviderModalActive = true;
      await this.auditStore.getProviderAudit({
        provider: provider._id,
        transactionsLimit: 50,
      });
    },
    async fetchProviderBalances() {
      this.isLoading = true;
      const { data: balances } = await StatisticsService.providerBalances();
      this.providerBalances = balances.sort((a, b) => a.name.localeCompare(b.name));
      this.isLoading = false;
    },
  },
  async mounted() {
    console.log('mounted');
    await this.fetchProviderBalances();
    await this.auditStore.getStaleProviderAudit();
  },
};
</script>
