<template>
  <div>
    <div class="flex flex-row-reverse items-center justify-start gap-6 py-5 pr-5 my-5">
      <b-button type="is-primary" @click="handleNewCustomer" data-cy="new-customer">Nuevo Cliente</b-button>

      <div v-if="isAdmin">
        <b-field :label="isBudget ? 'Presupuesto ' : 'Facturado'">
          <b-switch v-model="isBudget" :rounded="true" :outlined="true" type="is-success" :left-label="true"
            passive-type="is-primary"></b-switch>
        </b-field>
      </div>
      <div v-if="isWholesaler">
        <b-field :label="isSupply ? 'Socio ' : 'Venta'">
          <b-switch v-model="isSupply" :rounded="true" :outlined="true" type="is-success" :left-label="true"
            passive-type="is-primary"></b-switch>
        </b-field>
      </div>
      <div v-if="currentUser.wholesaler">
        <b-field :label="isWholesaler ? 'Mayorista' : 'Minorista'">
          <b-switch v-model="isWholesaler" :rounded="true" :outlined="true" type="is-success" :left-label="true"
            passive-type="is-primary"></b-switch>
        </b-field>
      </div>
    </div>
    <section class="mx-4">
      <div class="grid grid-cols-1 my-4 space-x-4 lg:grid-cols-2">
        <div class="flex flex-col gap-4">
          <ProductLookup ref="productLookup" :selectedProduct="selectedProduct" @select="handleSelectProduct">
          </ProductLookup>
          <AddItemToCart ref="addItemsToCart" :product="selectedProduct" :isWholesaler="isWholesaler"
            :isBudget="isBudget" @add="handleAddItemToCart" />
          <ItemsDetails v-if="cart.items.length" :cart="cart" :delivery="delivery" :canRemoveItems="true"
            :showDetails="true" :showTotal="true" @removeItem="handleRemoveItem"></ItemsDetails>
        </div>
        <div class="flex flex-col gap-4">
          <CustomerLookup @select="handleSelectCustomer" :draft="draft" :partner="customPartner" :onlyWithPartner="true"
            v-if="!customer && readyToRenderCustomerLookup"></CustomerLookup>

          <div class="p-4 border-2 border-purple-400 rounded-lg"
            v-if="customer && customer.notes && customer.notes.length">
            <div v-for="(note, index) of customer.notes.split('\n')" :key="index">
              <p>{{ note }}</p>
            </div>
          </div>

          <div class="p-4 border-2 border-purple-400 rounded-lg" v-if="customer">
            <button class="is-pulled-right delete is-small" v-if="!isUpdatingOrder"
              @click="handleRemoveCustomer"></button>
            <b class="is-pulled-right"></b>
            <p class="title is-5">
              {{ customer.name }}
              <span class="text-xs font-light">({{ customer.email }})</span>
            </p>
            <b-field label="Telefono">
              <PhoneInput v-model="delivery.phone" :phone="delivery.phone" />
            </b-field>

            <!-- <pre>deli.address, {{delivery.address}}</pre> -->

            <p v-if="draft" class="text-xs uppercase">Dirección borrador: {{ shortAddress(draft.address) }}</p>
            <AddressInput v-model="delivery.address" :address="delivery.address" />
            <b-field label="Correo de contacto">
              <b-input v-model="delivery.contactEmail" />
            </b-field>
          </div>
          <DraftInfo v-if="isDraftingOrder" :draft="draft"></DraftInfo>
          <div class="p-2 mb-4 bg-yellow-100 border-2 border-yellow-400 rounded-lg" v-if="
            isDraftingOrder && draft.partner._id !== currentUser._id && isAdmin
          ">
            <p class="text-lg font-bold uppercase">x {{ draft.partner.username }}</p>
          </div>


          <UserLookup v-if="isAdmin" ref="userLookup" :selectedUser="selectedUser" @select="handleSelectUser"
            :canRemove="!isUpdatingOrder"></UserLookup>

          <div v-if="deliveries.length">
            <b-field label="Logística a cargo de:">
              <b-select data-cy="delivery-method" v-model="delivery.name" placeholder="Seleccionar tipo de entrega:"
                expanded :disabled="hasShortageItems">
                <option v-for="option in deliveriesToChoose.map(({ name }) => name)" :value="option" :key="option">
                  {{ option | capitalize }}
                </option>
              </b-select>
            </b-field>

            <div class="field" v-if="delivery.name">
              <b-tag class="ml-2">Socio <b> ${{ getPartnerCost }}</b></b-tag>
              <b-tag class="ml-2">Cliente <b> ${{ getCustomerCost }}</b></b-tag>
              <b-tooltip label="Aporte de activate">
                <b-tag class="ml-2">Activate <b> ${{ getInternCost }}</b></b-tag>
              </b-tooltip>

              <b-tooltip v-if="isSelectedDeliveryDropOff" label="Es punto de entrega">
                <b-tag class="ml-2" type="is-primary">Punto de entrega </b-tag>
              </b-tooltip>
            </div>
            <div class="field" v-if="isDeliveryBonusable">
              <b-checkbox data-cy="bonify-delivery" v-model="delivery.charged" type="is-success" :true-value="false"
                :false-value="true">Bonificar costo de envío</b-checkbox>
            </div>

            <b-field label="Fecha de entrega:">
              <b-datepicker data-cy="delivery-date" :mobile-native="false" :unselectable-days-of-week="[0]"
                v-model="delivery.date" :min-date="minDate" :nearby-selectable-month-days="true"
                placeholder="Fecha de entrega" icon="calendar-today" trap-focus locale="es-ES"
                :events="nextDeliveryDates" indicators="dots">
                <li style="color: #8c67ef">
                  <span style="color: #2e323a">Fechas de entrega confirmadas</span>
                </li>
                <hr v-if="delivery.date" style="margin-top: 15px" />
                <button v-if="delivery.date" class="button is-danger" style="margin-top: -0.5em"
                  @click="handleRemoveDeliveryDate">
                  <b-icon icon="close"></b-icon>
                  <span>Borrar</span>
                </button>
              </b-datepicker>
            </b-field>
          </div>

          <b-field label="Metodo de pago:">
            <b-select data-cy="charge-method" v-model="paymentMethod" placeholder="Seleccionar metodo de pago:"
              expanded>
              <option v-for="option in chargeMethods.map(({ name }) => name)" :value="option" :key="option">
                {{ option | capitalize }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Observaciones del pedido" class="mt-2">
            <b-input data-cy="notes" v-model="notes" pattern="[a-z]*" maxlength="200" type="textarea"></b-input>
          </b-field>

          <b-field v-if="isAdmin" label="Descuento de aportes" class="mt-2">
            <b-numberinput v-model="discount" controls-alignment="right" controls-rounded min="0"
              max="100"></b-numberinput>
          </b-field>

          <div>
            <OrderDetails v-if="cart.items.length || delivery.name" :cart="cart" :delivery="delivery"
              :discount="discount" />
            <div class="mt-10 is-clearfix">
              <div class="buttons is-pulled-right">
                <Button data-cy="create-order" type="is-primary" :disabled="!cart.items.length ||
                  !customer ||
                  !delivery.name ||
                  !paymentMethod
                  " :onClickFn="isUpdatingOrder ? handleUpdateOrder : handleCheckoutOrder
                    ">Confirmar</Button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>
    <NewCustomer :active="newCustomerModalIsActive" :draft="draft" @created="handleCustomerCreated"
      @dismiss="newCustomerModalIsActive = false"></NewCustomer>
    <!-- <pre>{{ cart }}</pre> -->
    <!-- <pre>{{ delivery }}</pre> -->
  </div>
</template>

<script>
import ActivateSDK, { schemas } from 'activate-schemas';
import ProductLookup from '../components/Cart/ProductLookup.vue';
import CustomerLookup from '../components/Cart/CustomerLookup.vue';
import AddItemToCart from '../components/Cart/AddItemToCart.vue';
import OrderDetails from '../components/Cart/OrderDetails.vue';
import ItemsDetails from '../components/Cart/ItemsDetails.vue';
import NewCustomer from '../components/Customer/NewCustomer.vue';
import AddressInput from '../components/AddressInput.vue';
import ApiService from '../services/ApiService';

import {
  getNextDeliveryDates,
  validatePayload,
  disconnectedCopy,
  round,
  sum,
  shortAddress,
} from '../util/helpers';

import { ROLES } from '../util/constants';

import DraftInfo from '../components/Cart/DraftInfo.vue';
import PhoneInput from '../components/PhoneInput.vue';
import UserLookup from '../components/Cart/UserLookup.vue';

const { DeliveryService } = ApiService;

const { ADMIN } = ROLES;

const {
  order: { orderSchema: createOrderSchema, modifyOrderSchema },
} = schemas;

const { OrderService, ChargeMethodService } = ApiService;

const {
  models: { Cart },
} = ActivateSDK;


export default {
  name: 'Cart',
  props: {},
  components: {
    PhoneInput,
    ProductLookup,
    CustomerLookup,
    AddItemToCart,
    OrderDetails,
    ItemsDetails,
    NewCustomer,
    DraftInfo,
    AddressInput,
    UserLookup,
  },
  data() {
    const today = new Date();
    return {
      newCustomerModalIsActive: false,
      products: [],
      cart: new Cart(),
      discount: 0,
      delivery: {
        _id: null,
        name: null,
        address: null,
        phone: null,
        contactEmail: null,
        date: null,
        contributes: true,
        charged: true,
        customerPrice: 0,
        partnerPrice: 0,
        isDropOff: false,
      },
      deliveries: [],
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      nextDeliveryDates: getNextDeliveryDates(),
      chargeMethods: [],
      paymentMethod: null,
      total: 0,
      isSelectedDeliveryDropOff: false,
      notes: null,
      selectedProduct: null,
      selectedUser: null,
      customer: null,
      isUpdatingOrder: false,
      isDraftingOrder: false,
      orderId: null,
      hasShortageItems: false,
      isWholesaler: false,
      isBudget: false,
      isSupply: false,
      overwriteDelivery: true,
      isEditing: false,
      hasActions: true,
      readyToRenderCustomerLookup: false,
    };
  },
  watch: {
    'delivery.name': function () {
      if (!this.delivery) return;
      console.log('this.deliveriesChoose', this.deliveriesToChoose);
      this.delivery = {
        ...this.delivery,
        ...this.deliveriesToChoose.find(
          ({ name }) => name === this.delivery.name,
        ),
        charged: true,
      };

      // if (this.isDropOffDelivery) {
      //   this.isSelectedDeliveryDropOff = this.delivery._id
      //     === this.$route.params.partner.dropOffPoint.delivery;

      //   this.delivery.customerPrice = !this.delivery.charged
      //     ? this.delivery.customerPrice
      //     : this.$route.params.partner.dropOffPoint.price;
      // }
    },
    isWholesaler() {
      if (!this.isWholesaler) {
        this.isBudget = false;
        this.isSupply = false;
      }
      this.cart.billItems('order', this.isWholesaler, {
        isBudget: this.isWholesaler ? this.isBudget : false,
        isSupply: this.isWholesaler ? this.isSupply : false,
      });
      if (this.overwriteDelivery) {
        this.delivery = {
          ...this.delivery,
          name: null,
          date: null,
          contributes: true,
          charged: true,
          customerPrice: 0,
          partnerPrice: 0,
        };
      }
      this.overwriteDelivery = true;
      this.discount = this.discount || 0;
    },
    isBudget() {
      this.cart.billItems('order', this.isWholesaler, {
        isBudget: this.isBudget,
        isSupply: this.isWholesaler ? this.isSupply : false,
      });
    },
    isSupply() {
      this.cart.billItems('order', this.isWholesaler, {
        isBudget: this.isBudget,
        isSupply: this.isWholesaler ? this.isSupply : false,
      });
    },
  },
  computed: {
    isDropOffDelivery() {
      return (
        this.$route.params.isDropOff
        && this.$route.params.partner.dropOffPoint
        && this.$route.params.partner.dropOffPoint.delivery
      );
    },
    getCustomerCost() {
      return this.delivery.charged ? this.delivery.customerPrice : 0;
    },
    getInternCost() {
      return this.delivery.internPrice;
    },
    getPartnerCost() {
      // eslint-disable-next-line no-nested-ternary
      return this.delivery.charged
        ? this.delivery.partnerPrice
        : this.delivery.contributes
          ? this.delivery.customerPrice + this.delivery.partnerPrice
          : this.delivery.partnerPrice;
    },
    isDeliveryBonusable() {
      if (this.isWholesaler) return false;
      return this.delivery.customerPrice > 0;
    },
    deliveriesToChoose() {
      const { role } = this.currentUser;
      console.log(this.deliveries);
      if (this.hasShortageItems) {
        return [
          this.deliveries.find((delivery) => delivery.roles.length === 1 && delivery.roles[0] === 'ADMIN'),
        ].filter((v) => v);
      }
      return (
        this.deliveries.filter(
          ({ isWholesaler, isRetailer, roles }) => (isWholesaler === this.isWholesaler
            || isRetailer === !this.isWholesaler)
            && roles.includes(role),
        ).filter((v) => v) || []
      );
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    draft() {
      return this.$route.params.isDraft ? this.$route.params : null;
    },
    contributions() {
      return round(
        sum(
          this.cart.items.map((i) => i.detail),
          'contribution',
        ),
      );
    },
    forAnotherPartner() {
      return (
        // eslint-disable-next-line no-underscore-dangle
        (this.isDraftingOrder
          && this.draft.partner._id !== this.currentUser._id
          && this.isAdmin)
        || this.selectedUser
      );
    },
    customPartner() {
      // eslint-disable-next-line no-underscore-dangle
      return this.forAnotherPartner
        ? this.selectedUser._id || this.draft.partner._id
        : null;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          ' Se van a descartar los cambios \n¿Estás seguro que querés salir?',
        )
      ) {
        return;
      }
    }
    next();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  async mounted() {
    window.onscroll = () => { };
    this.fetchChargeMethods();
    this.isUpdateOrder();
    this.isDraftOrder();
    await this.fetchDeliveries();
    this.setMinDate();
  },
  methods: {
    shortAddress,
    async fetchChargeMethods() {
      const { data: chargeMethods } = await ChargeMethodService.find();
      this.chargeMethods = chargeMethods;
    },
    handleUpdateDiscount(discount) {
      this.discount = discount;
    },
    handleNewCustomer() {
      this.newCustomerModalIsActive = true;
    },
    async isDraftOrder() {
      if (!this.$route.params.isDraft) {
        this.readyToRenderCustomerLookup = true;
        return;
      }
      this.isDraftingOrder = this.$route.params.isDraft;
      this.isWholesaler = this.$route.params.cart.items.some(
        ({ isWholesaler }) => isWholesaler,
      );

      this.isWholesaler = this.$route.params.isSupply ? true : this.isWholesaler;
      this.isSupply = this.$route.params.isSupply;

      if (this.$route.params.customer) {
        this.customer = this.$route.params.customer;
      }

      // TODO: TAKE PLACE_ID FROM ADDRESS

      // const {
      //   data: {
      //     items: { 0: result },
      //   },
      // } = await hereInstance.get('', {
      //   params: {
      //     apiKey: 'DLUXaB-4XEtbOzEh0sGM0Rlc2-zlmB12-MD-GE8wg68',
      //     in: 'countryCode:ARG',
      //     q: `${this.$route.params.address}, ${this.$route.params.city} ${
      //       this.$route.params.county || ''
      //     }`,
      //   },
      // });

      // if (result?.resultType === 'houseNumber') {
      //   const {
      //     id,
      //     address: {
      //       label, street, houseNumber, state,
      //     },
      //   } = result;

      //   this.delivery.address = {
      //     id,
      //     label,
      //     street: `${street} ${houseNumber}`,
      //     unit: this.$route.params.unit || '',
      //     city: this.$route.params.city,
      //     county: this.parseHereAddress(result).area,
      //     province: state,
      //   };
      // }

      this.delivery.phone = this.$route.params.phone;
      this.delivery.contactEmail = this.$route.params.contactEmail;
      this.paymentMethod = this.$route.params.payMethod;

      if (this.isDropOffDelivery) {
        this.delivery.isDropOff = true;
        this.delivery = {
          ...this.delivery,
          ...this.deliveries.find(
            (delivery) => delivery._id === this.$route.params.partner.dropOffPoint.delivery,
          ),
        };
        this.delivery.customerPrice = this.$route.params.partner.dropOffPoint.price;
      }

      this.readyToRenderCustomerLookup = true;
    },
    isUpdateOrder() {
      if (!this.$route.params.cart) return;
      this.isUpdatingOrder = !this.$route.params.isNew;

      const {
        _id: id,
        cart: { items },
        customer,
        partner,
        delivery = this.delivery,
        hasShortageItems,
        notes,
        isBudget,
        isSupply,
        discount,
        paymentMethod,
      } = this.$route.params;

      if (this.isUpdatingOrder && items.some((item) => item.isWholesaler)) {
        this.overwriteDelivery = false;
        this.isWholesaler = true;
        this.isBudget = isBudget;
        this.isSupply = isSupply;
      }

      this.discount = discount;
      this.orderId = id;
      this.cart = new Cart(items, 'order', isBudget, isSupply);
      this.selectedUser = partner;
      this.paymentMethod = paymentMethod;
      this.customer = customer;
      this.delivery = delivery;
      this.delivery.date = this.delivery.date && new Date(this.delivery.date);
      this.hasShortageItems = hasShortageItems;
      this.notes = notes;
    },
    handleSelectUser(user) {
      this.selectedUser = disconnectedCopy(user);
    },
    handleSelectProduct(product) {
      if (!product) return;
      this.selectedProduct = disconnectedCopy(product);

      if (product.status) {
        this.$refs.addItemsToCart.setFocusQuantity();
      }
    },
    handleRemoveItem(index) {
      this.isEditing = true;
      this.cart.removeItem(index);
      this.cart.billItems('order', this.isWholesaler, {
        isBudget: this.isBudget,
        isSupply: this.isSupply,
      });
      this.total = this.cart.total;
    },
    handleRemoveCustomer() {
      this.isEditing = true;
      this.customer = null;
      this.delivery.address = {};
    },
    handleSelectCustomer(customer) {
      this.isEditing = true;
      this.customer = customer;

      if (this.customer.address.place_id) {
        this.delivery.address = this.customer.address;
      }
      this.delivery.phone = this.customer.phone;
      this.delivery.contactEmail = this.customer.contactEmail || this.customer.email;

      if (this.isDropOffDelivery) {
        this.delivery.address = this.$route.params.partner.dropOffPoint.address;
      }
    },
    handleCustomerCreated({ customer }) {
      this.isEditing = true;
      this.newCustomerModalIsActive = false;
      this.handleSelectCustomer(customer);
    },
    handleAddItemToCart(item) {
      this.isEditing = true;
      this.cart.addItem(item, 'order');
      this.cart.billItems('order', this.isWholesaler, {
        isBudget: this.isBudget,
        isSupply: this.isSupply,
      });
      this.total = this.cart.total;
      this.selectedProduct = null;
    },
    setMinDate() {
      const today = new Date();
      this.minDate = this.currentUser.role === ADMIN
        ? new Date(today.getFullYear(), today.getMonth(), today.getDate())
        : new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1,
        );
    },
    parseContributesTagType(contributes) {
      return contributes ? 'is-warning is-light' : 'is-success is-light';
    },
    handleRemoveDeliveryDate() {
      this.delivery.date = null;
    },
    async fetchDeliveries() {
      const { data: deliveries } = await DeliveryService.find({ status: true });
      this.deliveries = deliveries;
    },

    async handleCheckoutOrder() {
      const {
        cart,
        customer: { _id: customer },
        delivery,
        discount,
        isBudget,
        isSupply,
        paymentMethod,
        notes,
      } = this;

      delete delivery.delivered;
      delete delivery.message;

      const clonedCart = new Cart(cart.items, 'order', isBudget, isSupply);

      clonedCart.depopulateProducts();

      // eslint-disable-next-line no-underscore-dangle
      const partner = this.forAnotherPartner
        ? this.selectedUser._id || this.draft.partner._id
        : this.currentUser._id;

      delete delivery.bonusThreshold;

      const payload = {
        cart: clonedCart,
        customer,
        delivery,
        partner,
        discount,
        isBudget,
        isSupply,
        notes,
        paymentMethod,
      };

      if (!validatePayload(payload, createOrderSchema)) return;

      await OrderService.create(payload, {
        // eslint-disable-next-line no-underscore-dangle
        draft: this.isDraftingOrder ? this.draft._id : false,
      });

      this.isEditing = false;

      if (this.isAdmin) {
        if (this.isDraftingOrder) {
          this.$router.push('/drafts');
        } else {
          this.$router.push('/orders');
        }
      } else {
        this.$router.push('/my-orders');
      }
    },
    async handleUpdateOrder() {
      const {
        cart, delivery, discount, notes, paymentMethod, isBudget, isSupply,
      } = this;

      const clonedCart = new Cart(cart.items, 'order', isBudget, isSupply);

      clonedCart.depopulateProducts();

      delete delivery.partnerPrice;
      delete delivery.customerPrice;
      delete delivery.delivered;
      delete delivery.message;
      delete delivery.bonusThreshold;

      const payload = {
        cart: clonedCart,
        delivery,
        discount,
        notes,
        isBudget,
        isSupply,
        paymentMethod,
      };

      if (!validatePayload(payload, modifyOrderSchema)) return;

      await OrderService.update(this.orderId, payload);
      this.isEditing = false;
      if (this.isAdmin) {
        this.$router.push('/orders');
      } else {
        this.$router.push('/my-orders');
      }
    },
  },
};
</script>
