<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <Button :onClickFn="performProviderAudit">
          {{ providerAudit.stale ? 'Necesita actualización' : 'Actualizar' }}
        </Button>
        <ExportProviderCashFlows v-if="providerAudit.transactions.length" :flows="providerAudit.transactions" />
      </div>
    </hero-bar>
    <Section>
      <Widgets>
        <Widget title="Saldo de cuenta corriente" :value="-providerAudit.dueBalance" prefix="$" cta="/provider/account">
        </Widget>
      </Widgets>
      <LoadingTable :active="!isFetched.providerAudit" />
      <List :data="providerAudit.transactions" :operationPermission="false">
      </List>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../../components/HeroBar.vue';
import Widgets from '../../components/Widgets/Widgets.vue';
import Widget from '../../components/Widgets/Widget.vue';
import ApiService from '../../services/ApiService';
import List from './account/List.vue';
import Section from '../../components/Section.vue';
import ExportProviderCashFlows from '../../components/ExportProviderCashFlows.vue';
import useAuditStore from '../../store/audit';

const { ProviderService } = ApiService;

export default {
  name: 'ProviderAccount',
  components: {
    HeroBar,
    List,
    Section,
    Widgets,
    ExportProviderCashFlows,
    Widget,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      hasActions: true,
      provider: null,
      statistics: {},
    };
  },
  computed: {
    ...mapState(useAuditStore, ['providerAudit']),
    ...mapState(useAuditStore, ['isLoading']),
    ...mapState(useAuditStore, ['isFetched']),
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  async mounted() {
    await this.fetchProvider();
    await this.auditStore.getProviderAudit({
      provider: this.provider._id,
      transactionsLimit: 99999,
    });
  },
  methods: {
    async performProviderAudit() {
      await this.auditStore.performProviderAudit({
        provider: this.provider._id,
      });
    },
    async fetchProvider() {
      const { data: provider } = await ProviderService.findOne('own');
      this.provider = provider;
      return provider;
    },
  },
};
</script>
