<template>
  <div>
    <Section>
      <NegativeRealAlert
        v-if="isFetched.orders && negativeRealProducts.length"
        :products="negativeRealProducts"
      ></NegativeRealAlert>
      <div
        v-if="isFetched.pending && pending.length"
        class="p-5 mb-4 border-2 border-yellow-400 shadow-sm rounded-xl lg:px-8 bg-yellow-50"
        @click.stop="handleRedirectToOrders"
      >
        <p class="text-sm font-bold text-center">
          Hay pedidos sin fecha de entrega
        </p>
      </div>
      <div class="columns">
        <div class="column is-3 is-offset-9">
          <b-datepicker
            :mobile-native="false"
            position="is-bottom-left"
            placeholder="Fecha de entrega"
            locale="es-ES"
            v-model="dateFilter"
            range
            @input="handleChangeDates"
            :nearby-selectable-month-days="true"
          >
            <button
              class="button is-warning"
              @click="
                dateFilter = [new Date(), new Date()];
                handleChangeDates();
              "
            >
              <b-icon icon="calendar-today"></b-icon>
              <span>Hoy</span>
            </button>

            <button
              class="mt-1 button is-danger is-pulled-right"
              @click="dateFilter = []"
            >
              <b-icon icon="close"></b-icon>
              <span>Borrar filtro</span>
            </button>
          </b-datepicker>
        </div>
      </div>
      <SearchBar
        :store="orderStore"
        :params="options"
        placeholder="✨ Buscá clientes y direcciones"
      ></SearchBar>

      <Layout v-if="isFetched.due && due.length">
        <List :loading="isLoading.due">
          <p class="px-2 text-2xl font-bold">
            {{ `⚠️ Vencidos - ${dueItemsQuantity()} items` }}
          </p>
          <OrderCard
            v-for="order in due"
            :key="order._id"
            :order="order"
            :operationPermission="true"
            :isCheckable="false"
          ></OrderCard>
        </List>
      </Layout>
      <div v-if="isFetched.orders" class="my-2">
        <div class="flex flex-row-reverse">
          <b-button class="is-primary is-light" @click="toggleCompact()">
            Vista {{ compact ? 'Compacta' : 'Normal' }}
          </b-button>
        </div>

        <div v-for="(day, index) of orderDays" :key="day" :id="day">
          <div
            class="relative p-2 px-4 my-2 shadow sm:rounded-lg bg-purple-50"
            @click="collapseDay(day)"
          >
            <button
              class="absolute top-2 right-5 no-focus"
              type="is-text"
              size="is-small"
            >
              <b-icon
                :icon="
                  collapsedDays.includes(day) ? 'chevron-up' : 'chevron-down'
                "
              />
            </button>
            <b-checkbox
              v-if="operationPermission"
              class="absolute p-0 -right-3 top-10"
              v-model="selectedDays"
              :native-value="day"
              type="is-primary"
              size="is-large"
              @input="handleSelectDay(day, index)"
            ></b-checkbox>

            <div class="grid items-center grid-cols-2">
              <div class="">
                <p class="text-lg font-bold text-indigo-600">
                  {{ day | completeDate }}
                </p>
                <p class="text-sm">
                  {{ day | shortDate }}
                </p>
                <p class="text-sm">
                  Pedidos:
                  <span class="font-bold">{{
                    getOrdersByDate(day).length
                  }}</span>
                </p>
                <p class="text-sm">
                  Items:
                  <span class="font-bold"> {{ itemsQuantityByDay(day) }}</span>
                </p>
              </div>
              <div
                style="cursor: pointer"
                v-if="getShortagesByDate(day).length"
                class="w-12"
                @click.stop="handleShowShortagesByDay(day)"
              >
                <b-icon
                  icon="package-variant"
                  class="flex-none text-red-400"
                  size="is-large"
                ></b-icon>
              </div>
            </div>
          </div>

          <Layout v-show="!collapsedDays.includes(day)">
            <List :loading="isLoading.orders">
              <OrderCard
                v-for="order in getOrdersByDate(day)"
                :key="order._id"
                :order="order"
                :operationPermission="true"
                :compact="compact"
                :isCheckable="true"
              ></OrderCard>
            </List>
          </Layout>
        </div>
      </div>

      <OrderActions
        :selected="selected"
        :operationPermission="operationPermission"
        @deselectAll="handleDeselectAll"
        :actions="{
        splittedCategories: true,
        roadmap: false,
        invoice: true,
        consignment: true,
        deliveryRoute: false,
        export: true,
        prepare: true,
      }"

      ></OrderActions>
      <DayShortage
        :active="isDayShortageModalActice"
        :products="shortageProducts"
        @dismiss="isDayShortageModalActice = false"
      ></DayShortage>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { startOfDay, endOfDay, addDays } from 'date-fns';
import { ORDER_STATES } from '../util/constants';
import Layout from '../components/Layout.vue';
import DayShortage from '../components/Order/mobile/DayShortage.vue';
import OrderActions from '../components/Order/OrderActions.vue';
import SearchBar from '../components/SearchBar.vue';
import useOrderStore from '../store/order';
import List from '../components/List.vue';
import OrderCard from '../components/Order/OrderCard.vue';
import NegativeRealAlert from '../components/NegativeRealAlert.vue';
import ApiService from '../services/ApiService';
import Section from '../components/Section.vue';

const { InventoryService } = ApiService;

export default {
  name: 'Prepare',
  components: {
    OrderActions,
    NegativeRealAlert,
    SearchBar,
    DayShortage,
    Layout,
    List,
    OrderCard,
    Section,
  },
  data() {
    return {
      orderStore: useOrderStore(),
      isDayShortageModalActice: false,
      dateFilter: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
      shortageProducts: [],
      operationPermission: true,
      selectedDays: [],
      collapsedDays: [],
      compact: false,
      params: {},
      negativeRealProducts: [],
    };
  },
  computed: {
    ...mapState(useOrderStore, ['pagination']),
    ...mapState(useOrderStore, ['isFetched']),
    ...mapState(useOrderStore, ['isLoading']),
    ...mapState(useOrderStore, ['due']),
    ...mapState(useOrderStore, ['pending']),
    ...mapState(useOrderStore, ['orders']),
    ...mapState(useOrderStore, ['selected']),
    orders: {
      get() {
        return this.orderStore.orders;
      },
      set(value) {
        return this.orderStore.updatePositions(value, 'orders');
      },
    },
    orderDays() {
      return this.getOrderDays();
    },
    options() {
      return {
        state: JSON.stringify([ORDER_STATES.CONFIRMED, ORDER_STATES.PREPARED]),
        fromDate: this.dateFilter[0],
        toDate: this.dateFilter[1],
        limit: 999,
      };
    },
  },
  async mounted() {
    this.orderStore.setQuery(null);
    await this.orderStore.find(this.options);
    await this.orderStore.findDue();
    await this.orderStore.findPending();
    this.collapsedDays = this.getOrderDays();
    const {
      data: {
        products,
      },
    } = await InventoryService.findNegative();
    this.negativeRealProducts = products;
    await this.userStore.find({ isDriver: true }, { key: 'drivers' });
  },
  methods: {
    handleRedirectToOrders() {
      this.$router.push('/orders');
    },
    getOrderDays() {
      return [
        ...new Set(this.orders.map(({ delivery }) => delivery.date).sort()),
      ];
    },
    handleShowShortagesByDay(day) {
      this.shortageProducts = this.getShortagesByDate(day);
      this.isDayShortageModalActice = true;
    },
    handleDeselectAll() {
      this.selectedDays = [];
    },
    collapseDay(day) {
      const index = this.collapsedDays.indexOf(day);
      if (index === -1) {
        this.collapsedDays.push(day);
      } else {
        this.collapsedDays.splice(index, 1);
      }
    },
    handleSelectDay(day) {
      if (this.selectedDays.includes(day)) {
        this.orderStore.selectDay(day);
      } else {
        this.orderStore.deselectDay(day);
      }
    },
    toggleCompact() {
      this.compact = !this.compact;
    },
    itemsQuantityByDay(day) {
      return this.getOrdersByDate(day).reduce((acc, { cart: { items } }) => {
        // eslint-disable-next-line no-param-reassign
        acc += items.length;
        return acc;
      }, 0);
    },
    dueItemsQuantity() {
      return this.due.reduce((acc, { cart: { items } }) => {
        // eslint-disable-next-line no-param-reassign
        acc += items.length;
        return acc;
      }, 0);
    },
    getOrdersByDate(day) {
      return this.orders.filter(({ delivery }) => delivery.date === day);
    },
    getOrdersFromPreviousDates(day) {
      return this.getOrderDays()
        .filter((date) => date < day)
        .flatMap((date) => this.getOrdersByDate(date));
    },
    getShortagesByDate(day) {
      const currentDayProducts = this.getOrdersByDate(day)
        .filter(({ state }) => state === ORDER_STATES.CONFIRMED)
        .reduce((acc, { cart: { items } }) => {
          acc.push(
            ...items.map((item) => ({
              product: item.product,
              quantity: item.quantity,
            })),
          );
          return acc;
        }, []);

      const previousDaysProducts = this.getOrdersFromPreviousDates(day)
        .filter(({ state }) => state === ORDER_STATES.CONFIRMED)
        .reduce((acc, { cart: { items } }) => {
          acc.push(
            ...items.map((item) => ({
              product: item.product,
              quantity: item.quantity,
              isPrevious: true,
            })),
          );
          return acc;
        }, [])
        .filter(({ product }) => currentDayProducts.some(
          ({ product: { _id } }) => _id === product._id,
        ));

      return [...currentDayProducts, ...previousDaysProducts]
        .reduce((acc, { product, quantity, ...rest }) => {
          // eslint-disable-next-line no-underscore-dangle
          const index = acc.findIndex(
            ({ product: { _id } }) => _id === product._id,
          );
          if (index === -1) {
            acc.push({
              product,
              quantity,
              ...rest,
            });
          } else {
            acc[index].quantity += quantity;
            acc[index] = { ...acc[index], ...rest };
          }
          return acc;
        }, [])
        .filter(
          ({ product, quantity }) => product.inventory[0].real - quantity < 0,
        )
        .sort((a, b) => (a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1));
    },
    async handleChangeDates() {
      await this.orderStore.find(this.options);
      this.collapsedDays = this.getOrderDays();
    },
  },
};
</script>

<style>
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
</style>
