<template>
  <div class="ml-2">
    <b-button :loading="isLoading" type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary" /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportCashFlows',
  props: {
    flows: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      content: this.flows || [],
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    parseConcept(concept) {
      return {
        EGRESS: 'Egreso',
        INGRESS: 'Ingreso',
      }[concept];
    },
    getMetadata(type, {
      provider, driver, partner, third, tag,
    }) {
      let key = null;

      if (provider) {
        key = 'provider';
      } else if (driver) {
        key = 'driver';
      } else if (partner) {
        key = 'partner';
      } else if (third) {
        key = 'external';
      } else key = 'internal';

      return {
        provider: {
          name: provider && provider.name,
          label: 'Proveedor',
        },
        driver: {
          name: driver && driver.name,
          label: 'Repartidor',
        },
        partner: {
          name: partner && partner.username,
          label: 'AV',
        },
        external: {
          name: third && third.name,
          label: 'Prestamo',
        },
        internal: {
          name: tag,
          label: 'Resultado',
        },
      }[key][type];
    },
    async downloadFile() {
      this.isLoading = true;
      this.content = this.flows;
      const data = [
        {
          sheet: 'Flujos de caja',
          columns: [
            {
              label: 'Fecha',
              value: (flow) => new Date(flow.date),
              format: 'DD/MM/YYYY',
            },
            {
              label: 'Periodo',
              value: (flow) => new Date(flow.date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: (flow) => new Date(flow.date).getFullYear(),
            },
            {
              label: 'Concepto',
              value: (flow) => this.parseConcept(flow.concept),
            },
            {
              label: 'Tercero',
              value: (flow) => this.getMetadata('name', flow),
            },
            {
              label: 'X',
              value: (flow) => (flow.isBudget ? 'P' : 'F'),
            },
            { label: 'Tipo', value: (flow) => this.getMetadata('label', flow) },
            { label: 'Método de pago', value: 'paymentMethod' },
            { label: 'Monto', value: 'amount', format: '$0.00' },
            { label: 'Observaciones', value: 'description' },
          ],
          content: this.flows,
        },
        {
          sheet: 'Flujos de caja V2',
          columns: [
            {
              label: 'Fecha',
              value: (flow) => new Date(flow.date),
              format: 'DD/MM/YYYY',
            },
            {
              label: 'Periodo',
              value: (flow) => new Date(flow.date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: (flow) => new Date(flow.date).getFullYear(),
            },
            {
              label: 'Concepto',
              value: (flow) => this.parseConcept(flow.concept),
            },
            {
              label: 'Tercero',
              value: (flow) => this.getMetadata('name', flow),
            },
            {
              label: 'X',
              value: (flow) => (flow.isBudget ? 'P' : 'F'),
            },
            { label: 'Tipo', value: (flow) => this.getMetadata('label', flow) },
            { label: 'Método de pago', value: 'paymentMethod' },
            { label: 'Monto', value: 'amount', format: '$0.00' },
            { label: 'Observaciones', value: 'description' },
          ],
          content: [...this.flows].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          ),
        },
      ];
      const settings = {
        fileName: `Flujos de caja - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
      this.isLoading = false;
    },
  },
};
</script>
