<template>
  <b-modal :active="active" :has-modal-card="true" :can-cancel="['escape', 'outside']" @close="$emit('dismiss')">
    <div class="border-2 sm:rounded-lg modal-card">
      <section class="modal-card-body">
        <p class="title is-4">
          Generar comprobante
          <span v-if="receipt.buy">compra #{{ receipt.buy.number }}</span>
        </p>
        <ProviderLookup v-model="receipt.provider" :provider="receipt.provider || {}" :disabled="!!receipt.buy" />
        <b-field label="Tipo de comprobante">
          <b-select v-model="receipt.type" placeholder="Seleccionar tipo">
            <option v-for="_type in types" :value="_type.value" :key="_type.value">
              {{ _type.name }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="receipt.isBudget ? 'Presupuesto ' : 'Facturado'">
          <b-switch v-model="receipt.isBudget" :rounded="true" :outlined="true" type="is-success" :left-label="true"
            passive-type="is-primary"></b-switch>
        </b-field>

        <b-field label="Identificador">
          <b-input type="text" placeholder="" v-model="receipt.code" icon="pound" expanded></b-input>
        </b-field>

        <div v-if="!receipt.isBudget">
          <label for="taxes"><b>Impuestos</b></label>
          <div class="flex flex-col">
            <div v-for="tax of receipt.taxes" :key="tax._id" class="flex flex-row items-center justify-evenly">
              <p>{{ tax.name }}</p>
              <b-numberinput v-model="tax.value" :controls="false" :min="0" step="0.001">
              </b-numberinput>
              <button class="is-pulled-right delete is-small" @click="handleRemoveTax(tax)"></button>
            </div>
          </div>

          <div class="flex flex-row mt-2 justify-evenly">
            <b-field label="Tipo">
              <b-select v-model="taxSelected" placeholder="Seleccionar">
                <option v-for="option in taxes" :value="option.name" :key="option.name">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Agregar">
              <b-button type="is-success is-light" icon-right="plus" @click="handleAddTax()" /></b-field>
          </div>
        </div>

        <b-field label="Importe">
          <b-input type="number" placeholder="0.00" step="0.01" v-model="receipt.grossTotal" icon="currency-usd"
            expanded></b-input>
        </b-field>

        <div class="flex flex-row justify-between py-4 text-xl font-bold">
          <p>Total</p>
          <p>{{ total | currency }}</p>
        </div>

        <b-field v-if="receipt.buy"><b-checkbox :disabled="buy.withdrawal.fee === 0 || !buy.withdrawal.charged"
            v-model="logisticCreditNote" :value="true">¿Querés generar una nota de credito por la Logística?
            <span class="font-bold text-purple-500" :class="[!buy.withdrawal.charged ? 'line-through' : '']">
              {{ buy.withdrawal.fee | currency }}
            </span></b-checkbox></b-field>

        <b-field label="Descripción">
          <b-input maxlength="200" type="textarea" v-model="receipt.description"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="$emit('dismiss')">Cancelar</button>
        <Button data-cy="create-receipt" type="is-success" :onClickFn="handleCreateReceipt">Aceptar</Button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../services/ApiService';
import ProviderLookup from '../Cart/ProviderLookup.vue';
import { defaultData } from '../../schemas/receipt';
import { validatePayload, disconnectedCopy, sum } from '../../util/helpers';
import TaxService from '../../services/tax';

const { ReceiptService, ProviderService } = ApiService;
const {
  receipt: { receiptSchema: schema },
} = schemas;

export default {
  name: 'NewReceipt',
  props: {
    active: Boolean,
    buy: Object,
  },
  components: {
    ProviderLookup,
  },
  data() {
    return {
      types: [
        { name: 'Factura', value: 'FC' },
        { name: 'Nota de credito', value: 'NC' },
        { name: 'Nota de debito', value: 'ND' },
      ],
      receipt: disconnectedCopy(defaultData),
      logisticCreditNote: false,
      providers: [],
      taxes: [],
      taxSelected: null,
    };
  },
  computed: {
    total() {
      return +this.receipt.grossTotal + sum(this.receipt.taxes, 'value');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'receipt.isBudget': function () {
      if (this.receipt.isBudget) {
        this.receipt.taxes = [];
      }
    },
    // eslint-disable-next-line func-names
    'buy.number': async function () {
      if (this.buy.number) {
        this.receipt.buy = this.buy;
        this.receipt.provider = this.buy.provider;
        this.receipt.grossTotal = this.buy.cart.total;
        this.receipt.isBudget = this.buy.isBudget;
      } else {
        const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
        this.providers = providers;
      }
    },
  },
  methods: {
    handleAddTax() {
      const taxSelected = this.taxes.find(
        (tax) => tax.name === this.taxSelected,
      );

      const duplicated = this.receipt.taxes.find(
        (tax) => tax.name === taxSelected.name,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un impuesto con ese nombre');
        return;
      }

      this.receipt.taxes.push({ ...taxSelected, value: 0 });
      this.receipt.taxes.sort((a, b) => a.aliquot - b.aliquot);
    },
    handleRemoveTax(tax) {
      this.receipt.taxes = this.receipt.taxes.filter(
        ({ _id }) => _id !== tax._id,
      );
    },
    async handleCreateReceipt() {
      const payload = disconnectedCopy(this.receipt);

      // eslint-disable-next-line no-underscore-dangle
      payload.provider = (payload.provider && payload.provider._id) || null;
      // eslint-disable-next-line no-underscore-dangle
      payload.buy = (payload.buy && payload.buy._id) || null;
      payload.total = this.total;

      if (!validatePayload(payload, schema)) return;

      const { error, data: receipt } = await ReceiptService.create(payload, { logisticCreditNote: this.logisticCreditNote });

      if (error) {
        console.error(error.data.code);
        return;
      }
      this.$emit('created', receipt);
      this.$emit('dismiss');
      this.receipt = disconnectedCopy(defaultData);
    },
  },
  async mounted() {
    const taxes = await TaxService.findWithCache();
    this.taxes = taxes.filter(({ type }) => type === 'C');
    // eslint-disable-next-line prefer-destructuring
    this.taxSelected = this.taxes[0].name;
  },
};
</script>
