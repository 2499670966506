<template>
  <div>
    <div v-if="staleDriverAudit.count > 0" class="bg-red-400 flex items-center justify-between px-2">
      <span>Necesita actualizar auditoria repartidores</span>
      <Button :onClickFn="refreshAudit" label="Actualizar" type="is-danger">Auditar</Button>
    </div>
    <b-table :paginated="isPaginated" :perPage="perPage" :data="driversAudit" :narrowed="true" :loading="loading"
      :striped="true" :show-detail-icon="false" ref="table" detailed>
      <b-table-column field="name" label="Repartidor" centered sortable width="100px" :searchable="advancedTable"
        v-slot="props">
        <b-tooltip :label="props.row.name">
          <span @click="() => handleShowAuditPartnerModal(props.row.driver._id)" class="cursor-pointer">
            {{ props.row.name }}
          </span>
        </b-tooltip>
      </b-table-column>


      <b-table-column field="totalRides" label="Repartos" centered sortable v-slot="props">
        ${{ props.row.totalRides + props.row.totalBudgetRides }}
      </b-table-column>

      <b-table-column field="totalDriverCashFlows" label="Pagos de repartidor" centered sortable v-slot="props">
        ${{ props.row.totalDriverCashFlows + props.row.totalBudgetDriverCashFlows }}
      </b-table-column>

      <b-table-column field="dueBalance" label="Balance" centered sortable v-slot="props">
        ${{ props.row.dueBalance }}
      </b-table-column>

      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalRiders }}</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalDriverCashFlows }}</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalDueBalance }}</div>
        </th>

      </template>
    </b-table>

    <GenericModal :active="isAuditPartnerModalActive" @dismiss="isAuditPartnerModalActive = false">
      <AuditPartnerList v-if="driverAudit && driverAudit.length > 0" :transactions="driverAudit[0].transactions" />
    </GenericModal>

  </div>
</template>

<script>
import { mapState } from 'pinia';
import { round, sum } from '../../util/helpers';
import useAuditStore from '../../store/audit';
import GenericModal from '../GenericModal.vue';
import AuditPartnerList from '../AuditPartnerList.vue';

export default {
  name: 'DriversTable',
  props: {
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GenericModal,
    AuditPartnerList,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      type: 'Repartidor',
      paymentMethod: 'CASH',
      concept: 'INGRESS',
      amount: 0,
      description: null,

      isAuditPartnerModalActive: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(useAuditStore, ['driversAudit']),
    ...mapState(useAuditStore, ['driverAudit']),
    ...mapState(useAuditStore, ['staleDriverAudit']),
    totalRiders() {
      return round(
        sum(this.driversAudit, 'totalRides')
        + sum(this.driversAudit, 'totalBudgetRides'),
      );
    },
    totalDriverCashFlows() {
      return round(
        sum(this.driversAudit, 'totalDriverCashFlows')
        + sum(this.driversAudit, 'totalBudgetDriverCashFlows'),
      );
    },
    totalDueBalance() {
      return round(
        sum(this.driversAudit, 'dueBalance'),
      );
    },
  },
  methods: {
    async refreshAudit() {
      await this.auditStore.performDriverAudit();
      await this.auditStore.getDriversAudit(
        {
          limit: 99999,
          transactionsLimit: 1,
          populatePartner: true,
        },
        'driversAudit',
      );
      await this.auditStore.getStaleDriverAudit();
    },
    async handleShowAuditPartnerModal(driver) {
      await this.auditStore.getDriversAudit({
        limit: 1,
        transactionsLimit: 50,
        driver,
        populateCustomer: true,
      }, 'driverAudit');
      this.isAuditPartnerModalActive = true;
    },
  },
  async mounted() {
    await this.auditStore.getStaleDriverAudit();
    await this.auditStore.getDriversAudit(
      {
        limit: 99999,
        transactionsLimit: 1,
        populatePartner: true,
      },
      'driversAudit',
    );
  },
};
</script>
