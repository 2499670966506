<template>
  <div>
    <div v-if="stalePartnerAudit.count > 0" class="bg-red-400 flex items-center justify-between px-2">
      <span>Necesita actualizar auditoria socios</span>
      <Button :onClickFn="refreshAudit" label="Actualizar" type="is-danger">Auditar</Button>
    </div>
    <b-table :paginated="isPaginated" :perPage="perPage" :data="partnersAudit" :narrowed="true" :loading="loading"
      :striped="true" :show-detail-icon="false" ref="table" detailed>
      <b-table-column field="partner.name" label="Socio" centered sortable width="100px" :searchable="advancedTable"
        v-slot="props">
        <b-tooltip :label="props.row.name">
          <span @click="() => handleShowAuditPartnerModal(props.row.partner._id)" class="cursor-pointer">
            {{ props.row.name }}
          </span>
        </b-tooltip>
      </b-table-column>

      <b-table-column field="billed" label="Facturación" centered sortable v-slot="props">
        ${{ props.row.totalBilled + props.row.totalBudgetBilled }}
      </b-table-column>

      <b-table-column field="costs" label="Costos" centered sortable v-slot="props">
        ${{ props.row.cost }}
      </b-table-column>

      <b-table-column field="contributions" label="Aportes" centered sortable v-slot="props">
        ${{
          (props.row.contribution - props.row.deliveryInternPrice) | rounded
        }}
      </b-table-column>

      <b-table-column field="logistic" label="Logística" centered sortable v-slot="props">
        ${{ (props.row.logistic + props.row.deliveryInternPrice) | rounded }}
      </b-table-column>

      <b-table-column field="taxes" label="Impuestos" centered sortable v-slot="props">
        ${{ props.row.taxes }}
      </b-table-column>

      <b-table-column field="profits" label="Ganancia" centered sortable v-slot="props">
        ${{ props.row.profit }}
      </b-table-column>

      <b-table-column field="charged" label="Cobrado" centered sortable v-slot="props">
        ${{ props.row.totalCashFlows + (props.row.totalBudgetCashFlows || 0) }}
      </b-table-column>

      <b-table-column field="toPay" label="A Rendir" centered sortable v-slot="props">
        <b-tag size="is-medium" :class="props.row.dueBalance >= 0
          ? 'is-success is-light'
          : 'is-danger is-light'
          ">$ {{ Math.abs(props.row.dueBalance) }}</b-tag>
      </b-table-column>

      <b-table-column field="rate" label="%" centered sortable v-slot="props">
        {{ ((props.row.profit * 100) / props.row.totalBilled) | rounded }}%
      </b-table-column>

      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalBilled }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalCost }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalContributions }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalLogistic }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalTaxes }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalProfits }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalCharged }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalToPay }}</div>
        </th>
        <th class="is-hidden-mobile"></th>
      </template>
    </b-table>

    <GenericModal :active="isAuditPartnerModalActive" @dismiss="isAuditPartnerModalActive = false">
      <AuditPartnerList :transactions="partnerAudit.transactions" />
    </GenericModal>

  </div>
</template>

<script>
import { mapState } from 'pinia';
import { round, sum } from '../../util/helpers';
import useAuditStore from '../../store/audit';
import GenericModal from '../GenericModal.vue';
import AuditPartnerList from '../AuditPartnerList.vue';

export default {
  name: 'PartnersTable',
  props: {
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GenericModal,
    AuditPartnerList,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      type: 'Socio',
      paymentMethod: 'CASH',
      concept: 'INGRESS',
      amount: 0,
      description: null,

      isAuditPartnerModalActive: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(useAuditStore, ['partnersAudit']),
    ...mapState(useAuditStore, ['partnerAudit']),
    ...mapState(useAuditStore, ['stalePartnerAudit']),
    totalCost() {
      return round(sum(this.partnersAudit, 'cost'));
    },
    totalContributions() {
      return round(
        sum(
          this.partnersAudit,
          null,
          ({ contribution, deliveryInternPrice }) => contribution - deliveryInternPrice,
        ),
      );
    },
    totalLogistic() {
      return round(
        sum(
          this.partnersAudit,
          null,
          ({ logistic, deliveryInternPrice }) => logistic + deliveryInternPrice,
        ),
      );
    },
    totalTaxes() {
      return round(sum(this.partnersAudit, 'taxes'));
    },
    totalToPay() {
      return -round(sum(this.partnersAudit, 'dueBalance'));
    },
    totalProfits() {
      return round(sum(this.partnersAudit, 'profit'));
    },
    totalCharged() {
      return round(
        sum(
          this.partnersAudit,
          null,
          ({ totalCashFlows, totalBudgetCashFlows }) => totalCashFlows + (totalBudgetCashFlows || 0),
        ),
      );
    },
    totalBilled() {
      return round(
        sum(this.partnersAudit, 'totalBilled')
        + sum(this.partnersAudit, 'totalBudgetBilled'),
      );
    },
  },
  methods: {
    toPay(partner) {
      return round(partner.toPayBalance);
    },
    async handleShowAuditPartnerModal(partner) {
      await this.auditStore.getPartnerAudit({
        limit: 1,
        transactionsLimit: 50,
        partner,
        populateCustomer: true,
      });
      this.isAuditPartnerModalActive = true;
    },
    async refreshAudit() {
      await this.auditStore.performPartnerAudit();
      await this.auditStore.getPartnersAudit(
        {
          limit: 99999,
          transactionsLimit: 1,
          populatePartner: true,
        },
        'partnersAudit',
      );
      await this.auditStore.getStalePartnerAudit();
    },
  },

  async mounted() {
    await this.auditStore.getStalePartnerAudit();
    await this.auditStore.getPartnersAudit(
      {
        limit: 99999,
        transactionsLimit: 1,
        populatePartner: true,
      },
      'partnersAudit',
    );
  },
};
</script>
