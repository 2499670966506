/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';

const { AuditService } = ApiService;

const useAuditStore = defineStore('audit', {
  state: () => ({
    profitsAudit: [],
    profitAudit: [], // for individual list transactions
    driversAudit: [],
    driverAudit: [], // for individual list transactions
    partnersAudit: [],
    partnerAudit: { transactions: [] },
    providerAudit: { transactions: [] },
    stalePartnerAudit: { count: 0 },
    staleProviderAudit: { count: 0 },
    staleDriverAudit: { count: 0 },
    staleProfitAudit: { count: 0 },
    staleCustomerAudit: { count: 0 },
    selected: [],
    isLoading: {
      partnerAudit: false,
      providerAudit: false,
    },
    isFetched: {
      providerAudit: false,
      partnerAudit: false,
    },
    query: null,
  }),
  actions: {
    async getStalePartnerAudit(params = {}) {
      const { data } = await AuditService.getPartnerStaleAudit({
        ...params,
      });
      this.stalePartnerAudit = data;
    },
    async getStaleProviderAudit(params = {}) {
      const { data } = await AuditService.getProviderStaleAudit({
        ...params,
      });
      this.staleProviderAudit = data;
    },
    async getStaleDriverAudit(params = {}) {
      const { data } = await AuditService.getDriverStaleAudit({
        ...params,
      });
      this.staleDriverAudit = data;
    },
    async getStaleProfitAudit(params = {}) {
      const { data } = await AuditService.getProfitStaleAudit({
        ...params,
      });
      this.staleProfitAudit = data;
    },
    async getStaleCustomerAudit(params = {}) {
      const { data } = await AuditService.getCustomerStaleAudit({
        ...params,
      });
      this.staleCustomerAudit = data;
    },
    async getPartnersAudit(params = {}, key = 'partnersAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getPartnerAudit({
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = result.docs.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getProfitsAudit(params = {}, key = 'profitsAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getProfitAudit({
        ...params,
      });

      console.log('result', result);
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      console.log('result', result.docs[0]);
      this[key] = result.docs.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getDriversAudit(params = {}, key = 'driversAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getDriverAudit({
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = result.docs.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getPartnerAudit(params = {}, key = 'partnerAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getPartnerAudit({
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = result.docs[0];
    },
    async performPartnerAudit(params = {}) {
      this.isLoading.partnerAudit = true;
      await AuditService.performPartnerAudit({
        ...params,
      });
      await this.getPartnerAudit(params);
    },
    async getProviderAudit(params = {}) {
      this.isLoading.providerAudit = true;
      const { data: result } = await AuditService.getProviderAudit({
        ...params,
      });
      this.isLoading.providerAudit = false;
      this.isFetched.providerAudit = true;
      this.providerAudit = result.docs[0];
    },
    async performProviderAudit(params = {}) {
      this.isLoading.providerAudit = true;
      await AuditService.performProviderAudit({
        ...params,
      });
      await this.getProviderAudit(params);
    },
    async performProfitAudit(params = {}) {
      this.isLoading.profitAudit = true;
      await AuditService.performProfitAudit({
        ...params,
      });
    },
    async performDriverAudit(params = {}) {
      this.isLoading.driverAudit = true;
      await AuditService.performDriverAudit({
        ...params,
      });
    },
  },
});

export default useAuditStore;
