<template>
  <div class="card">
    <div class="card-content">
      <div class="columns">
        <div class="column is-8">
          <b-field label="Concepto" label-position="inside">
            <b-select placeholder="Concepto" v-model="cashFlow.concept" expanded>
              <option value="INGRESS">Ingreso</option>
              <option value="EGRESS">Egreso</option>
            </b-select>
          </b-field>

          <b-field v-if="
            isAdmin &&
            (this.cashFlow.type === 'commercial.partner' ||
              this.cashFlow.type === 'commercial.provider' ||
              this.cashFlow.type === 'profit' ||
              this.cashFlow.type === 'driver')
          " :label="isBudget ? 'Presupuesto ' : 'Facturado'">
            <b-switch v-model="isBudget" :rounded="true" :outlined="true" type="is-success" :left-label="true"
              passive-type="is-primary"></b-switch>
          </b-field>

          <b-field label="Método de pago" label-position="inside">
            <b-select placeholder="Método de pago" v-model="cashFlow.paymentMethod" expanded>
              <option v-for="option in payMethods.map(({ name }) => name)" :value="option" :key="option">
                {{ option | capitalize }}
              </option>
            </b-select>
          </b-field>

          <TypeInput :type="cashFlow.type" :concept="cashFlow.concept" @select="handleSelectType" />

          <ThirdPartyLookup v-if="hasThird" :thirds="getThirds" :third="selectedThird" @select="handleSelectThird"
            :type="thirdType" />

          <TagInput v-if="isInternal" @select="handleSelectTag"></TagInput>
        </div>
        <div class="column is-4">
          <CustomerLookup label="Asociar cuenta cliente" @select="handleSelectCustomer" :partner="cashFlow.partner"
            :onlyWithPartner="true" v-if="
              cashFlow.concept === 'INGRESS' &&
              isCommercialPartner &&
              cashFlow.partner
            "></CustomerLookup>
          <b-field label="Importe">
            <b-input type="number" placeholder="0.00" step="0.01" v-model="cashFlow.amount" icon="currency-usd"
              expanded></b-input>
          </b-field>
          <b-field label="Descripción" label-position="inside">
            <b-input maxlength="200
            " type="textarea" v-model="cashFlow.description"></b-input>
          </b-field>

          <!-- <pre>{{ cashFlow }}</pre> -->

          <Button data-cy="create-cashflow" :disabled="(hasThird && !selectedThird) ||
            cashFlow.amount === 0 ||
            !cashFlow.paymentMethod
            " type="is-success" label="Confirmar" pulled="right" :onClickFn="handleCreateCashFlow">Confirmar</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThirdPartyLookup from './ThirdPartyLookup.vue';
import ApiService from '../../services/ApiService';
import TypeInput from './TypeInput.vue';
import TagInput from './TagInput.vue';
import CustomerLookup from '../Cart/CustomerLookup.vue';

const { PayMethodService, CashFlowService } = ApiService;

export default {
  name: 'NewCashFlow',
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    providers: {
      type: Array,
      default: () => [],
    },
    thirds: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ThirdPartyLookup,
    TypeInput,
    TagInput,
    CustomerLookup,
  },
  data() {
    return {
      cashFlow: {
        type: null,
        paymentMethod: null,
        concept: null,
        amount: 0,
        customer: null,
        description: null,
      },
      payMethods: [],
      selectedThird: null,
      isBudget: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'cashFlow.concept': function () {
      this.cashFlow.type = this.isIngress
        ? 'commercial.partner'
        : 'profit';
      this.selectedThird = null;
    },
    isBudget() { },
  },
  computed: {
    isIngress() {
      return this.cashFlow.concept === 'INGRESS';
    },
    isEgress() {
      return this.cashFlow.concept === 'EGRESS';
    },
    hasThird() {
      if (!this.cashFlow.type) return false;
      return !this.isInternal;
    },
    getThirds() {
      if (!this.hasThird) return [];

      if (this.isCommercialPartner) return this.partners;
      if (this.isCommercialProvider) return this.providers;
      if (this.isProfit) return this.partners;
      if (this.isDriver) return this.partners.filter(({ isDriver }) => isDriver);
      return this.thirds;
    },
    isInternal() {
      return (
        this.cashFlow.type && this.cashFlow.type.split('.').includes('internal')
      );
    },
    isProfit() {
      if (!this.cashFlow.type) return false;
      return this.cashFlow.type === 'profit';
    },
    isDriver() {
      if (!this.cashFlow.type) return false;
      return this.cashFlow.type === 'driver';
    },
    isCommercialProvider() {
      if (!this.cashFlow.type) return false;
      return this.cashFlow.type === 'commercial.provider';
    },
    isCommercialPartner() {
      if (!this.cashFlow.type) return false;
      return this.cashFlow.type === 'commercial.partner';
    },
    thirdType() {
      if (!this.hasThird) return '';

      if (this.isCommercialPartner) return 'Socio';
      if (this.isCommercialProvider) return 'Proveedor';
      if (this.isProfit) return 'Socio';
      if (this.isDriver) return 'Repartidor';
      if (this.isInternal) return 'Deudor / Acreedor';
      return 'Deudor / Acreedor';
    },
    currentGarage() {
      return this.$store.getters['Auth/currentUser'].garage || {};
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  methods: {
    handleSelectType(type) {
      this.cashFlow.type = type;
    },
    handleSelectTag(tag) {
      this.cashFlow.tag = tag;
    },
    handleSelectThird(third) {
      this.cashFlow.customer = null;
      this.selectedThird = third;
      if (!third) return;
      const { _id: thirdId } = third;
      const { _id: garageId } = this.currentGarage;
      const { type } = this.cashFlow;
      if (type === 'commercial.partner') {
        this.cashFlow.partner = thirdId;
        this.cashFlow.garage = garageId;
      } else if (type === 'commercial.provider') {
        this.cashFlow.provider = thirdId;
        this.cashFlow.garage = garageId;
      } else if (type === 'external') {
        this.cashFlow.third = thirdId;
        this.cashFlow.garage = garageId;
      } else if (type === 'profit') {
        this.cashFlow.partner = thirdId;
        this.cashFlow.garage = garageId;
      } else if (type === 'driver') {
        this.cashFlow.driver = thirdId;
        this.cashFlow.garage = garageId;
      }
    },
    handleSelectCustomer(customer) {
      // eslint-disable-next-line no-underscore-dangle
      this.cashFlow.customer = customer._id;
      this.cashFlow.description = `Cobranza de ${customer.name}`;
    },
    async fetchPayMethods() {
      const { data: payMethods } = await PayMethodService.find({
        status: true,
      });
      this.payMethods = payMethods;
    },

    async handleCreateCashFlow() {
      const { type } = this.cashFlow;
      const payload = this.cashFlow;
      const { _id: garageId } = this.currentGarage;

      const createService = (() => {
        const {
          createProfit,
          createDriver,
          createPartner,
          createProvider,
          createInternal,
          createExternal,
        } = CashFlowService;
        const types = type.split('.');

        if (types.includes('profit')) {
          payload.isBudget = this.isBudget;
          delete this.cashFlow.customer;
          return createProfit;
        }

        if (types.includes('driver')) {
          payload.isBudget = this.isBudget;
          delete this.cashFlow.customer;
          return createDriver;
        }

        if (types.includes('commercial')) {
          payload.isBudget = this.isBudget;
          delete this.cashFlow.garage;

          if (this.isCommercialPartner) {
            delete this.cashFlow.provider;
            return createPartner;
          }

          if (this.isCommercialProvider) {
            delete this.cashFlow.customer;
            delete this.cashFlow.partner;
            return createProvider;
          }
        }

        delete this.cashFlow.provider;
        delete this.cashFlow.partner;
        delete this.cashFlow.garage;
        delete this.cashFlow.customer;
        if (types.includes('internal')) {
          this.cashFlow.garage = garageId;
          return createInternal;
        }
        return createExternal;
      })();

      if (payload.type === 'internal' && !payload.tag) {
        this.$buefy.snackbar.open('Flujos internos requiren de un tag');
        return;
      }

      const { data: createdCashFlow } = await createService(payload);
      this.selectedThird = null;
      this.cashFlow = {
        customer: null,
        partner: null,
        provider: null,
        type: null,
        paymentMethod: null,
        concept: null,
        amount: 0,
        description: null,
      };

      this.$emit('created', createdCashFlow);
    },
  },
  async mounted() {
    await this.fetchPayMethods();
  },
};
</script>
