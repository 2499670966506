<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <MainWidgets />

      <div class="flex flex-col gap-4">
        <div class="flex gap-4">
          <Button :onClickFn="performInventoryAudit">Auditar Inventario</Button>
          <Button :onClickFn="performPartnerAudit">Auditar AVs</Button>
          <Button :onClickFn="performDriverAudit">Auditar Repartidores</Button>
          <Button :onClickFn="performProfitAudit">Auditar Ganancias</Button>
          <Button :onClickFn="performProviderAudit">Auditar Proveedores</Button>
        </div>
        <div class="flex g-4">
          <Button :onClickFn="performCommercialAudit">Auditar Tenencias</Button>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import MainWidgets from '../components/Widgets/MainWidgets.vue';
import Section from '../components/Section.vue';
import ApiService from '../services/ApiService';

const { AuditService } = ApiService;

export default {
  name: 'Dashboard',
  components: {
    Section,
    HeroBar,
    MainWidgets,
  },
  data() {
    return {
      hasActions: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.meta.title;
    },
  },
  mounted() { },
  methods: {
    async performInventoryAudit() {
      await AuditService.performInventoryAudit();
    },
    async performCommercialAudit() {
      await AuditService.performCommercialAudit();
    },
    async performPartnerAudit(params) {
      await AuditService.performPartnerAudit(params);
    },
    async performProviderAudit() {
      await AuditService.performProviderAudit();
    },
    async performDriverAudit() {
      await AuditService.performDriverAudit();
    },
    async performProfitAudit() {
      await AuditService.performProfitAudit();
    },
  },
};
</script>
