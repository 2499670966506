<template>
  <div>
    <div v-if="staleProfitAudit.count > 0" class="bg-red-400 flex items-center justify-between px-2">
      <span>Necesita actualizar auditoria ganancias</span>
      <Button :onClickFn="refreshAudit" label="Actualizar" type="is-danger">Auditar</Button>
    </div>
    <b-table :paginated="isPaginated" :perPage="perPage" :data="profitsAudit" :narrowed="true" :loading="loading"
      :striped="true" :show-detail-icon="false" ref="table" detailed>
      <b-table-column field="name" label="Socio" centered sortable width="100px" :searchable="advancedTable"
        v-slot="props">
        <b-tooltip :label="props.row.name">
          <span @click="() => handleShowAuditPartnerModal(props.row.partner._id)" class="cursor-pointer">
            {{ props.row.name }}
          </span>
        </b-tooltip>
      </b-table-column>


      <b-table-column field="totalProfits" label="Ganancias" centered sortable v-slot="props">
        ${{ props.row.totalProfit + props.row.totalBudgetProfit }}
      </b-table-column>

      <b-table-column field="totalProfitCashFlows" label="Pagos de ganancia" centered sortable v-slot="props">
        ${{ props.row.totalProfitCashFlows + props.row.totalBudgetProfitCashFlows }}
      </b-table-column>

      <b-table-column field="dueBalance" label="Balance" centered sortable v-slot="props">
        ${{ props.row.dueBalance }}
      </b-table-column>

      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalProfits }}</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalProfitCashFlows }}</div>
        </th>

        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">${{ totalDueBalance }}</div>
        </th>

      </template>
    </b-table>

    <GenericModal :active="isAuditPartnerModalActive" @dismiss="isAuditPartnerModalActive = false">
      <AuditPartnerList v-if="profitAudit && profitAudit.length > 0" :transactions="profitAudit[0].transactions" />
    </GenericModal>

  </div>
</template>

<script>
import { mapState } from 'pinia';
import { round, sum } from '../../util/helpers';
import useAuditStore from '../../store/audit';
import GenericModal from '../GenericModal.vue';
import AuditPartnerList from '../AuditPartnerList.vue';

export default {
  name: 'ProfitsTable',
  props: {
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GenericModal,
    AuditPartnerList,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      type: 'Socio',
      paymentMethod: 'CASH',
      concept: 'INGRESS',
      amount: 0,
      description: null,

      isAuditPartnerModalActive: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(useAuditStore, ['profitsAudit']),
    ...mapState(useAuditStore, ['profitAudit']),
    ...mapState(useAuditStore, ['staleProfitAudit']),
    totalProfits() {
      return round(
        sum(this.profitsAudit, 'totalProfit')
        + sum(this.profitsAudit, 'totalBudgetProfit'),
      );
    },
    totalProfitCashFlows() {
      return round(
        sum(this.profitsAudit, 'totalProfitCashFlows')
        + sum(this.profitsAudit, 'totalBudgetProfitCashFlows'),
      );
    },
    totalDueBalance() {
      return round(
        sum(this.profitsAudit, 'dueBalance'),
      );
    },
  },
  methods: {
    async refreshAudit() {
      await this.auditStore.performProfitAudit();
    },
    async handleShowAuditPartnerModal(partner) {
      await this.auditStore.getProfitsAudit({
        limit: 1,
        transactionsLimit: 50,
        partner,
        populateCustomer: true,
      }, 'profitAudit');
      this.isAuditPartnerModalActive = true;
    },
  },
  async mounted() {
    await this.auditStore.getStaleProfitAudit();
    await this.auditStore.getProfitsAudit(
      {
        limit: 99999,
        transactionsLimit: 1,
        populatePartner: true,
      },
      'profitsAudit',
    );
  },
};
</script>
