<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right" v-if="isAdmin">
        <b-button type="is-primary is-light" icon-left="nutrition"
          @click="handleRedirectCatalogView">Catalogo</b-button>
        <b-button type="is-primary is-light" icon-left="pot-mix"
          @click="handleRedirectProvidersView">Proveedores</b-button>
        <b-button type="is-primary is-light" icon-left="format-list-text"
          @click="handleRedirectCategoriesView">Categorías</b-button>
        <ExportAdjustments :disabled="!products.length" />
        <ExportProducts :disabled="!products.length" />
        <b-button data-cy="new-product" class="ml-3" @click="handleNewProduct">Nuevo Producto</b-button>
      </div>
    </hero-bar>
    <Section>
      <b-tabs v-model="activeTab">
        <b-tab-item label="Activos">
          <SearchBar :store="productStore" storeKey="products" :params="{ status: true, catalog: true }" placeholder="✨ Buscá productos">
          </SearchBar>
          <Layout>
            <List :loading="isLoading.products">
              <!-- <p class="px-2 text-2xl font-bold">Productos</p> -->
              <ProductCard v-for="product in products" :key="product._id" :product="product" :isCheckable="false"
                :operationPermission="isAdmin"></ProductCard>
            </List>
          </Layout>
        </b-tab-item>

        <b-tab-item label="No Catalogo">
          <Button :onClickFn="getProductsOutsideCatalog">Cargar No Catalogo</Button>
          <Layout>
            <List :loading="isLoading.productsOutsideCatalog">
              <!-- <p class="px-2 text-2xl font-bold">Productos</p> -->
              <ProductCard v-for="product in productsOutsideCatalog" :key="product._id" :product="product" :isCheckable="false"
                :operationPermission="isAdmin"></ProductCard>
            </List>
          </Layout>
        </b-tab-item>
        <b-tab-item label="Inactivos">
          <Button :onClickFn="getInactives">Cargar Inactivos</Button>
          <Layout>
            <List :loading="isLoading.inactives">
              <!-- <p class="px-2 text-2xl font-bold">Productos</p> -->
              <ProductCard v-for="product in inactives" :key="product._id" :product="product" :isCheckable="false"
                :operationPermission="isAdmin"></ProductCard>
            </List>
          </Layout>
        </b-tab-item>



      </b-tabs>

    </Section>
    <NewProduct v-if="isAdmin" :active="newProductModalIsActive" :components="products"
      @dismiss="newProductModalIsActive = false" @created="handleProductCreated"></NewProduct>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../components/HeroBar.vue';
// import ProductInfo from '../components/Product/Info.vue';
import ExportProducts from '../components/Product/ExportProducts.vue';
import ExportAdjustments from '../components/Product/ExportAdjustments.vue';
// import ProductTable from '../components/Product/ProductTable.vue';
import NewProduct from '../components/Product/NewProduct.vue';
// import Inventory from '../components/Product/Inventory.vue';
import ApiService from '../services/ApiService';
import ProductCard from '../components/Product/mobile/ProductCard.vue';
import SearchBar from '../components/SearchBar.vue';
import useProductStore from '../store/product';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import Section from '../components/Section.vue';

const { ProductService } = ApiService;

export default {
  name: 'Products',
  components: {
    HeroBar,
    ExportProducts,
    ExportAdjustments,
    NewProduct,
    Layout,
    List,
    ProductCard,
    SearchBar,
    Section,
  },
  data() {
    return {
      activeTab: 0,
      productStore: useProductStore(),
      ProductService,
      query: {},
      newProductModalIsActive: false,
      isFilterHidden: true,
      hasActions: true,
    };
  },
  computed: {
    ...mapState(useProductStore, ['options']),
    ...mapState(useProductStore, ['pagination']),
    ...mapState(useProductStore, ['isLoading']),
    products: {
      get() {
        return this.productStore.products;
      },
      set(value) {
        return this.productStore.updatePositions(value, 'products');
      },
    },
    inactives: {
      get() {
        return [...this.productStore.inactives].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      },
      set(value) {
        return this.productStore.updatePositions(value, 'inactives');
      },
    },
    productsOutsideCatalog: {
      get() {
        return [...this.productStore.productsOutsideCatalog].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      },
      set(value) {
        return this.productStore.updatePositions(value, 'productsOutsideCatalog');
      },
    },
    ...mapState(useProductStore, ['selected']),
    isAdmin() {
      return this.$store.getters['Auth/currentUser'].role === 'ADMIN';
    },
  },
  async mounted() {
    this.getNext();
    await this.productStore.find({ status: true, catalog: true }, { key: 'products' });
  },
  watch: {},
  methods: {
    getInactives() {
      this.productStore.find({ 
        status: false, page: 1, limit: 999, query: undefined,
      }, { key: 'inactives' });
    },
    getProductsOutsideCatalog() {
      this.productStore.find({
        status: true,
        catalog: false,
        page: 1,
        limit: 999,
        query: undefined,
      }, { key: 'productsOutsideCatalog' });
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.products
          && this.pagination.hasNextPage
        ) {
          this.handleChangePage();
        }
      };
    },
    handleSearch(results, query) {
      this.loading = false;
      this.query.query = query;
      this.products = results.docs;
      this.options.page = results.page;
      this.pagination = results;
    },
    handleChangePage() {
      this.productStore.changePage({ status: true, catalog: true });
    },
    handleRedirectCatalogView() {
      this.$router.push('catalog');
    },
    handleRedirectProvidersView() {
      this.$router.push('providers');
    },
    handleRedirectCategoriesView() {
      this.$router.push('categories');
    },
    handleNewProduct() {
      this.newProductModalIsActive = true;
    },
    handleSelectProduct(product) {
      this.selected = { ...product, options: product.options || {} };
    },
    handleProductCreated() {
      this.newProductModalIsActive = false;
    },
    handleUpdateCritical(critical) {
      this.selected.inventory[0].critical = critical;
    },
  },
};
</script>
