<!-- eslint-disable max-len -->
<template>
  <div class="p-2 bg-white border-l-2 border-red-400 shadow-lg sm:rounded-lg">
    <div class="grid grid-cols-3 gap-4 rounded-lg" @click="handleSelect(order)">
      <div class="col-span-2">
        <p class="p-1 text-xs">{{ order.date | longDate }}</p>
        <p class="flex items-center py-1 text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>

          <span class="flex-grow ml-2 font-bold"
            >Pedido de {{ order.customerName }}
          </span>
        </p>
        <p class="flex items-center">
          <StateComponent class="px-4 ml-2" :element="order"></StateComponent>

          <svg
            v-if="isLoadingOrder && loadingOrder === order._id"
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 animate-spin"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </p>
      </div>
      <div class="flex flex-col items-end justify-center">
        <p class="ml-2 text-xl font-bold">{{ order[this.field] | currency }}</p>
        <p class="text-sm">{{ order.current | currency }}</p>
      </div>
    </div>

    <Details
      :active="isDetailsOpen"
      :element="elementSelected"
      @dismiss="handleDismissDetails"
    ></Details>
  </div>
</template>

<script>
import Details from './DetailsModal.vue';
import StateComponent from './StateComponent.vue';
import ApiService from '../services/ApiService';

const { OrderService } = ApiService;

export default {
  name: 'OrderDebtCard',
  props: {
    order: { type: Object },
    field: { 
      type: String,
      default: 'toPay',
    },
    operationPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
  },
  components: {
    Details,
    StateComponent,
  },
  data() {
    return {
      details: false,
      isDetailsOpen: false,
      isLoadingOrder: false,
      loadingOrder: null,
      elementSelected: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async handleSelect(element) {
      this.loadingOrder = element._id;
      this.isLoadingOrder = true;
      const { data: order } = await OrderService.findOne(element._id);
      this.elementSelected = order;
      this.isLoadingOrder = false;
      this.isDetailsOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.elementSelected = null;
    },
  },
  watch: {},
};
</script>
