<template>
  <b-field>
    <b-radio-button v-if="isIngress" v-model="newType" native-value="commercial.partner" size="is-small">
      Rendición de Socio </b-radio-button>
    <b-radio-button v-model="newType" native-value="profit" size="is-small" v-if="isEgress">
      Ganancia de Socio
    </b-radio-button>

    <b-radio-button v-model="newType" native-value="external" size="is-small">
      Prestamo
    </b-radio-button>

    <b-radio-button v-model="newType" native-value="commercial.provider" size="is-small" v-if="isEgress">
      Pago a proveedor
    </b-radio-button>

    <b-radio-button v-model="newType" native-value="driver" size="is-small" v-if="isEgress">
      Pago a repartidor
    </b-radio-button>

    <b-radio-button v-model="newType" native-value="internal" size="is-small">
      {{ isEgress ? "Gasto" : "Ganancia" }}
    </b-radio-button>

    <b-radio-button v-model="newType" native-value="internal.adjust" size="is-small">
      Ajuste
    </b-radio-button>
  </b-field>
</template>

<script>
export default {
  name: 'TypeInput',
  props: {
    concept: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      newType: null,
    };
  },
  watch: {
    newType() {
      if (!this.newType) return;
      this.$emit('select', this.newType);
    },
    type() {
      if (!this.type) return;
      this.newType = this.type;
    },
  },
  computed: {
    isIngress() {
      return this.concept === 'INGRESS';
    },
    isEgress() {
      return this.concept === 'EGRESS';
    },
    isExternal() {
      return this.type === 'EGRESS';
    },
  },
  methods: {},
  mounted() { },
};
</script>
