import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { ProductService } = ApiService;

const useProductStore = defineStore('product', {
  state: () => ({
    products: [],
    inactives: [],
    productsOutsideCatalog: [],
    selected: [],
    isLoading: {
      products: false,
      inactives: false,
      productsOutsideCatalog: false,
    },
    isFetched: {
      products: false,
      inactives: false,
      productsOutsideCatalog: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false, key = 'products' } = {}) {
      this.isLoading[key] = true;
      const { data: pagination } = await ProductService.find({
        query: this.query,
        page: this.options.page,
        limit: this.options.limit,
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = merge ? [...this[key], ...pagination.docs] : pagination.docs;
      this.pagination = pagination;
    },

    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(products) {
      this.products = products;
    },
    select(product) {
      this.selected.push(product);
    },
    deselect(product) {
      const index = this.selected.findIndex(({ _id }) => _id === product._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(product) {
      return this.products.findIndex(({ _id }) => _id === product._id);
    },
    updatePositions(products, key) {
      this[key] = products;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const copy = [...this[key]];
        copy[index] = element;
        this[key] = copy;
      }
    },
    async disable(id) {
      const { data: updated } = await ProductService.disable(id);
      this.updateElement(updated, 'products');
      return updated;
    },
    async enable(id) {
      const { data: updated } = await ProductService.enable(id);
      this.updateElement(updated, 'products');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await ProductService.update(id, payload);
      this.updateElement(updated, 'products');
      return updated;
    },
    async updateCriticalInventory(id, payload) {
      const { data: updated } = await ProductService.updateCriticalInventory(
        id,
        payload,
      );
      this.updateElement(updated, 'products');
      return updated;
    },
    async adjustInventory(id, payload) {
      const { data: updated } = await ProductService.adjustInventory(
        id,
        payload,
      );
      this.updateElement(updated, 'products');
      return updated;
    },
    async forceAdjustment(id, payload) {
      const { data: updated } = await ProductService.forceAdjustment(
        id,
        payload,
      );
      this.updateElement(updated, 'products');
      return updated;
    },
  },
});

export default useProductStore;
